import React from "react";
import { RTF } from "../elements";
import { CleanedRTF } from "@bond-london/graphcms-rich-text";

interface Props {
  storyRTF?: CleanedRTF;
}

export const Story: React.FC<Props> = ({ storyRTF }) => {
  if (!storyRTF) {
    return null;
  }
  return (
    <section
      data-component="Story"
      className="py-section relative page-grid z-10"
    >
      <RTF content={storyRTF} className="col-start-2 col-span-12" />
    </section>
  );
};
