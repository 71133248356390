import React from "react";
import { graphql, PageProps } from "gatsby";
import { Query } from "../../../generated/graphql-types";
import { Hero, Story } from "../../../components";
import { getCleanedRTF } from "@bond-london/graphcms-rich-text";
import { buildMenu, Layout } from "../../../layouts";

const component: React.FC<PageProps<Pick<Query, "graphCmsStory">>> = ({
  path,
  data: { graphCmsStory: story },
}) => {
  if (!story) return null;
  return (
    <Layout
      pagePath={path}
      menu={buildMenu(story.menu)}
      footerMenu={buildMenu(story.footerMenu)}
      keywords={story.keywords}
      title={story.title}
      description={story.description}
    >
      <Hero
        title={story.title}
        short={true}
        colour={story.heroColour}
        showSearch={true}
      />
      <Story storyRTF={getCleanedRTF(story.details)} />
    </Layout>
  );
};

export default component;

export const storyQuery = graphql`
  query StoryQuery($id: String) {
    graphCmsStory(id: { eq: $id }) {
      menu {
        ...MenuFragment
      }
      id
      title
      heroColour
      details {
        cleaned
      }
      keywords
      description
    }
  }
`;
